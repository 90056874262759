// i18next-extract-mark-ns-start contact-sales

//Payment methods
import {Content} from 'components/Content';
import {HubspotForm} from 'components/HubspotForm';
import {Section, SectionHeader} from 'components/Section';
import {SEO} from 'components/SEO';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import React, {useEffect} from 'react';
import styled from 'styled-components';

const IndexBackground = styled.div`
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: -8.9%;
    height: 1256px;
    width: 1203px;
    background: linear-gradient(353.74deg, #41efd6 -47.57%, #aab4e9 13.85%);
    transform: matrix(2, 0, 0, -1, 1248, 4) rotate(47deg) skew(7deg, 1.6deg);
    @media (max-width: 1024px) {
      top: -11.5%;
      transform: matrix(2, 0, 0, -1, 1185, 25) rotate(44.5deg) skew(7deg, 1.6deg);
    }
    @media (max-width: 768px) {
      content: none;
    }
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    transform: rotate(34deg) matrix(1, 0, 0, -1, 459, -315);
    left: 50%;
    top: -3%;
    opacity: 1;
    height: 972px;
    width: 1289px;
    background: linear-gradient(353.74deg, #41efd6 -41.57%, #aab4e9 53.85%);
    @media (max-width: 1024px) {
      top: -13%;
      transform: rotate(34deg) matrix(1, 0, 0, -1, 470, -240);
    }
    @media (max-width: 768px) {
      content: none;
    }
  }
`;

const IndexContent = styled.div`
  padding-top: 30px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const ContactSales: React.FC = () => {
  const {t} = useI18next();

  return (
    <>
      <SEO
        path="contact-sales"
        title="Contact Sales"
        description={t(
          'Contact our sales team to learn more about how MONEI can help you grow your business.'
        )}
      />
      <IndexBackground>
        <Content>
          <Section>
            <IndexContent>
              <SectionHeader underline tagName="h1">
                <Trans>I need a payment solution</Trans>
              </SectionHeader>
              <Trans parent="p">
                Contact our sales team to learn more about how MONEI can help you grow your
                business.
              </Trans>
              <Trans parent="p">
                In your message, add all additional information and questions you have so that we
                can respond to your needs as quickly as possible.
              </Trans>
              <Trans parent="p">
                <strong>Once the form is completed we will contact you as soon as possible.</strong>
              </Trans>
            </IndexContent>
            <HubspotForm formId="0424b220-d7c2-488b-8208-190a7fed546a" minHeight={1002} />
          </Section>
        </Content>
      </IndexBackground>
    </>
  );
};

export default ContactSales;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "contact-sales"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
